<template>
  <!-- Money svg -->
  <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
 <g class="layer">
  <title>Layer 1</title>
  <use id="svg_3" transform="matrix(0.0533911 0 0 0.0533911 -33.8368 -25.1129)" x="632.83" xlink:href="#svg_2" y="486.03"/>
 </g>
 <defs>
  <symbol height="500" id="svg_2" version="1.1" width="500" xmlns="http://www.w3.org/2000/svg">
   <path d="m145,312c-2,69 31,100 104,102c78,1 113,-34 109,-101c-6,-58 -62,-73 -106,-79c-48,-17 -99,-25 -99,-95c0,-48 32,-79 99,-78c60,0 97,25 96,84" fill="none" stroke="currentColor" stroke-width="40"/>
   <path d="m250,15l0,470" stroke="currentColor" stroke-width="30"/>
  </symbol>
 </defs>
</svg>
</template>

<script>
export default {
  name: 'MoneyIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>