<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_1')" :preview="false" drawer />
      <Loader v-if="loading" />
      <template v-else>
        <EmptyState
          v-if="list.length === 0"
          :title="$tr('project', 'key_20')"
          :subtitle="$tr('project', 'key_21')"
        >
          <template v-slot:icon>
            <FragmentIcon />
          </template>
          <template v-slot:action>
            <!-- <v-btn
              depressed
              outlined
              color="primary"
              large
              ripple
              @click="$emit('open_launcher')"
            >
              {{ $tr("project", "key_2") }}
            </v-btn> -->
            <a href="#" class="btn-shine" @click="$emit('open_launcher')">Create Your First Application</a>

          </template>
        </EmptyState>
        <v-container v-else fluid>
          <v-card outlined class="apps-welcome-card">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">OVERVIEW</div>
                <v-list-item-title v-if="!first_name" class="text-h5 mb-1">
                  {{ salutationBasedOnClientTime() }} there! 👋
                </v-list-item-title>
                <v-list-item-title v-else class="text-h5 mb-1">
                  {{ salutationBasedOnClientTime() }}, {{ first_name }}! 👋
                </v-list-item-title>
                <v-list-item-subtitle
                  >You have {{ this.appswithStatusTrue }} applications ready to
                  be built, {{ this.appswithStatusFalse }} applications in
                  progress.</v-list-item-subtitle
                >
              </v-list-item-content>

              <!-- <v-list-item-avatar
        tile
        size="80"
        color="grey"
      ></v-list-item-avatar> -->
            </v-list-item>

            <v-card-actions>
              <v-btn outlined text depressed @click="$emit('open_launcher')">
                <v-icon left> mdi-plus </v-icon>
                <div class="text-caption">New application</div>
              </v-btn>
              <v-btn outlined text depressed to="/account/newsletter">
                <v-icon left> mdi mdi-bell-plus-outline </v-icon>
                <div class="text-caption">Send new notification</div>
              </v-btn>
            </v-card-actions>
          </v-card>
          <div class="app_grid">
            <div v-for="(app, index) in list" :key="'app_' + index">
              <router-link
                :to="{ name: 'Main', params: { uid: app.uid } }"
                style="color: inherit"
              >
                <div class="app_card">
                  <div style="margin-right: 20px">
                    <AppIcon :image="!app.icon ? null : app.icon" />
                  </div>
                  <div>
                    <div
                      class="font-weight-bold d-inline-block text-truncate"
                      style="max-width: 280px; margin-bottom: 0"
                    >
                      {{ app.name }}
                    </div>
                    <div
                      class="caption text-truncate"
                      style="max-width: 280px; margin-bottom: 6px"
                    >
                      {{ app.link }}
                    </div>
                    <div>
                      <StatusBadge :status="!app.status ? false : true" />
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div
            v-if="loadBtn.status && count > 20"
            class="d-flex justify-center mt-8 mb-5"
          >
            <v-btn
              rounded
              outlined
              :loading="loadBtn.loading"
              @click="loadMore"
            >
              {{ $tr("project", "key_26") }}
            </v-btn>
          </div>
        </v-container>
      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
import EmptyState from "@/components/blocks/EmptyState";
import FragmentIcon from "@/components/icons/FragmentIcon";
import AppIcon from "@/components/blocks/AppIcon";
import StatusBadge from "@/components/blocks/StatusBadge";
export default {
  name: "Apps",
  components: {
    PageBar,
    Loader,
    EmptyState,
    FragmentIcon,
    AppIcon,
    StatusBadge,
  },
  data: () => ({
    sort: 0,
    loading: true,
    list: [],
    offset: 0,
    first_name: "",
    currentTime: new Date(),
    count: 0,
    appswithStatusFalse: 0,
    appswithStatusTrue: 0,
    loadBtn: {
      status: true,
      loading: false,
    },
  }),
  methods: {
    getAppsList() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/list/${this.sort}/${this.offset}`
        )
        .then((response) => {
          this.list = response.data.apps;
          this.appswithStatusFalse = this.list.filter(
            (app) => app.status === 0
          ).length;
          this.appswithStatusTrue = this.list.filter(
            (app) => app.status === 1
          ).length;
          this.loading = false;
          this.offset += 20;
          this.count = response.data.count;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    loadMore() {
      this.loadBtn.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/list/${this.sort}/${this.offset}`
        )
        .then((response) => {
          if (response.data.apps.length > 0) {
            for (let i = 0; i < response.data.apps.length; i++) {
              this.list.push(response.data.apps[i]);
            }
          } else {
            this.loadBtn.status = false;
          }
          this.offset += 20;
          this.loadBtn.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loadBtn.loading = false;
        });
    },
    getUserName() {
      this.first_name = this.$store.state.user.first_name;
    },
    salutationBasedOnClientTime(){
      let hour = this.currentTime.getHours();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    }
  },
  mounted() {
    this.getAppsList();
    this.getUserName();
  },
};
</script>
<style scoped>
.btn-shine {
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #fff;
  background: linear-gradient(to right, #959cb7 0, #fff 10%, #959cb7 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
}
@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  100% {
    background-position: 300px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  100% {
    background-position: 300px;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  100% {
    background-position: 300px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  100% {
    background-position: 300px;
  }
}

</style>
