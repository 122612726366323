<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_39')" :preview="false" drawer />
      <Loader v-if="loading" />
      <template v-else>
        <v-container fluid>
          <v-card outlined class="affiliates-welcome-card">
            <v-row>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle class="mb-1">
                      Welcome to the Affiliate Program
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-h5 mb-1 text-no-wrap">
                      Invite your friends and earn lifelong<br />recurring
                      commissions from every <br />purchase they make. 🎁
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                  <v-text-field
                    outlined
                    filled
                    dense
                    full-width
                    hint="Your affiliate link"
                    readonly
                    v-model="affiliateLink"
                    append-icon="mdi-content-copy"
                    @click:append="copyToClipboard"
                  ></v-text-field>
                </v-card-actions>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-card flat class="affiliates-welcome-card">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-center">
                        Earnings
                      </v-list-item-subtitle>
                      <v-list-item-title
                        class="text-h1 text-center font-weight-bold"
                      >
                        ${{ earnings }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-center">
                        Comission Rate: <b>10%</b>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="mb-6" no-gutters>
            <v-col>
              <v-card class="pa-2 mr-2 affiliates-welcome-card" outlined tile>
                <!--Title of How it Works -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      How it Works
                    </v-list-item-title>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-avatar color="snow" size="40">
                        <v-icon  color="primary">mdi mdi-numeric-1</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text--secondary">
                          You send your invitation link to your friends.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>

                        <v-avatar color="snow" size="40">
                        <v-icon color="primary" >mdi mdi-numeric-2</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text--secondary"
                          >They subscribe to a paid plan by using your referral
                          link.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>

                        <v-avatar color="snow" size="40">
                        <v-icon color="primary">mdi mdi-numeric-3</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text--secondary"
                          >From their first purchase, you will begin earning
                          <br />recurring commissions.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item-subtitle class="mb-3">
                          Affiliate link
                        </v-list-item-subtitle>
                        <v-text-field
            outlined
            placeholder="Email Address"
            append-icon="mdi mdi-email-outline"
          ></v-text-field>
          <v-btn type="email" color="primary" depressed dense>Send</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2 affiliates-welcome-card" outlined tile>
                <v-list-item class="mb-12">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 mb-5">
                      Withdrawal Form
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-3">
                      Your Bank Information
                    </v-list-item-subtitle>
                    <v-textarea outlined name="input-7-4" clearable dense rows="4"
                    placeholder="Bank of America - 2382372329 3843749 2372379"
                    ></v-textarea>
                    <v-list-item-subtitle class="mb-3">
                      Amount
                    </v-list-item-subtitle>
                    <v-text-field outlined type="number" dense placeholder="Minimum amount is $10"
                    ></v-text-field>
                    <v-btn color="primary" depressed>Send Request</v-btn>  
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mb-12" no-gutters>
            <v-col>
              <v-card class="pa-2 affiliates-welcome-card" outlined tile>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Email</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in referrals" :key="item.email">
                        <td>{{ item.email }}</td>
                        <td>{{ formatDate(item.created_at) }}</td>
                        <td>No Payment Yet</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
//   import AppIcon from "@/components/blocks/AppIcon";
//   import StatusBadge from "@/components/blocks/StatusBadge";
export default {
  name: "Affiliate",
  components: {
    PageBar,
    Loader,
    //   AppIcon,
    //   StatusBadge,
  },
  data: () => ({
    affiliateLink: "https://builder.penako.com/invite/123456",
    referrals: [],
    earnings: 0,
    sort: 0,
    loading: true,
    list: [],
    offset: 0,
    count: 0,
    appswithStatusFalse: 0,
    appswithStatusTrue: 0,
    loadBtn: {
      status: true,
      loading: false,
    },
  }),
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.affiliateLink);
      this.$store.commit("setSnackBar", {
        code: 200,
        message: ["Link copied to clipboard"],
      });
    },
    getReferralLink() {
      this.loading = true;
      this.$http
        .get(`${this.$serverApiLink}api/account/affiliate/link`)
        .then((response) => {
          this.affiliateLink = response.data.link;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    getAffiliateStats() {
      this.loading = true;
      this.$http
        .get(`${this.$serverApiLink}api/account/affiliate/statistics`)
        .then((response) => {
          this.referrals = response.data.referrals;
          this.earnings = response.data.earnings;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    formatDate(timestamp) {
      let date = new Date(timestamp * 1000);
      return date.toLocaleDateString("en-US");
    },
  },
  mounted() {
    this.getReferralLink();
    this.getAffiliateStats();
  },
};
</script>

<style scoped>
.affiliates-welcome-card {
  padding: 20px;
  border-radius: 12px;
  background: transparent !important;
  justify-content: start;
  align-items: center;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 12px;
  border-radius: 12px !important;
  max-width: 100%;
  -moz-transition: background 0.1s ease-in;
  -o-transition: background 0.1s ease-in;
  -webkit-transition: background 0.1s ease-in;
}
</style>
